import React from "react";
import pdf from "../Assets/pdf/CluberlyReport.pdf";

export default function Dashboard() {
  return (
    <div className="pdf-viewer">
      <iframe src={pdf + "#toolbar=0"} width="100%;" height="100vh" />
    </div>
  );
}
