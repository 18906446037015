import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  return (
    <section className="cmn-background">
      <Container>
        <Row className="align-items-center">
          <Col xl={5} lg={5} md={6} className="white-sec login">
            <div>
              <Row className="second-sec">
                <Col lg={12}>
                  <h2 className="cmn-heading">Forgot Password</h2>
                </Col>
                <Col lg={12}>
                  <p className="sub-heading">
                    Please input your information in the fields to Change Your
                    Password
                  </p>
                </Col>
              </Row>
              <hr className="cmn-border" />

              <Row>
                <Col lg={12} className="mb-4 pb-4">
                  <div class="form-group">
                    <div className="d-flex justify-content-between">
                      <label>Account</label>
                      <span>
                        <img
                          src={require("../Assets/images/info.svg").default}
                        />
                      </span>
                    </div>
                    <div className="mail-img">
                      <img
                        src={
                          require("../Assets/images/account-one.svg").default
                        }
                      />
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={12} className="d-flex justify-content-center">
                  <button type="submit" class="cmn-btn">
                    Request password change
                  </button>
                </Col>
              </Row>
            </div>
            <div>
              <Col lg={12}>
                <div className="last-sec">
                  <p className="sub-heading">Do you need help?</p>
                  <button className="remember">
                    <Link to="/register">Customer support</Link>
                  </button>
                </div>
              </Col>
            </div>
          </Col>
          <Col xl={7} lg={7} md={6}>
            <div className="logo-center">
              <img
                src={require("../Assets/images/cluberly-white.svg").default}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
