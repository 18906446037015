import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from 'yup'
import Pdf from "../Assets/pdf/CluberlyReport.pdf#toolbar=0&navpanes=0";
import { useDispatch } from "react-redux";
import { loginreportAction } from "../Redux/Action/Authaction";
export default function Login() {
  const dispatch=useDispatch();

  const formik=useFormik({
    initialValues:{
      email:"",
      password:""
    },
    validationSchema:Yup.object().shape({
      email:Yup.string().email("Please enter a valid email").required('Email is required'),
      password:Yup.string().required('Password is required')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
      dispatch(loginreportAction(values))

    }
  })
  return (
    <section className="cmn-background">
      <Container>
        <Row className="align-items-center">
          <Col xl={5} lg={5} md={6} className="white-sec login">
            <div>
              <Row className="second-sec">
                <Col lg={12}>
                  <h2 className="cmn-heading">Log in</h2>
                </Col>
                <Col lg={12}>
                  <p className="sub-heading">
                    Please input your information in the fields below to enter
                    your portal
                  </p>
                </Col>
              </Row>
              <hr className="cmn-border" />

                <form  onSubmit={formik.handleSubmit}>


              <Row>
                <Col lg={12} className="space-set">
                  <div class="form-group">
                    <div className="d-flex justify-content-between">
                      <label>Account</label>
                      <span>
                        <img
                          src={require("../Assets/images/info.svg").default}
                        />
                      </span>
                    </div>
                    <div className="mail-img">
                      <img
                        src={
                          require("../Assets/images/account-one.svg").default
                        }
                      />
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                       />
                        {formik.errors.email && <div className="errors">{formik.errors.email}</div>}
                    </div>
                    <div className="mail-img border-bottom-set">
                      <img
                        src={require("../Assets/images/password.svg").default}
                      />
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Password"
                        name="password"
                        onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                       />
                        {formik.errors.email && <div className="errors">{formik.errors.email}</div>}
                    </div>
                    {/* <button className="remember">
                      <Link to="/forgot-password">Forgot your password?</Link>
                    </button> */}
                  </div>
                </Col>

                <Col lg={12} className="d-flex justify-content-center">
                  <button type="submit" class="cmn-btn">
                    Log in
                  </button>

           
                </Col>
              </Row>
              </form>
            </div>
            <div>
              {" "}
              <Col lg={12}>
                <div className="last-sec">
                  <p className="sub-heading">Don’t have an account yet?</p>
                  <button className="remember">
                    <Link to="/register">Register here</Link>
                  </button>
                </div>
              </Col>
            </div>
          </Col>
          <Col xl={7} lg={7} md={6}>
            <div className="logo-center">
              <img
                src={require("../Assets/images/cluberly-white.svg").default}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}




