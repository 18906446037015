import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";

import { ToastContainer, toast } from "react-toastify";


export const  addandupdatereportAction =createAsyncThunk('addandupdatereport',async(obj)=>{

       




        try {
            const  {data}  = await Api.post("/reportportal/addandupdatereport", obj);
          
          
      
            if(data?.status==201){
                toast.success("Profile created successfully")
                setTimeout( window.location.href="/",
                    
                    
                    
                    5000);


               
               
            
            }else{
            toast.error(data.message)
            }
    
        } catch (error) {
         
            toast.error(error.message)
        }


    })



    export const loginreportAction =createAsyncThunk('loginreport',async(obj)=>{

        try {
            const  data  = await Api.post("/reportportal/loginreport", obj);
            console.log(data.data)
          
          
      
            if(data?.data?.status==200){
                window.location.href="/dashboard"
                localStorage.setItem('accessToken',data.data.token.token);
                localStorage.setItem('userid',data.data.userdetails._id);
            
            }else{
            toast.error(data.data.message)
            }
    
        } catch (error) {
         
            toast.error(error.message)
        }


    })



  
    

export const  AddadmincreateAction =createAsyncThunk('Addadmincreate',async(obj)=>{

const  data  = await Api.post("/adminauth/addadmincreate", obj);
// console.log(data.data,"data");



})






export const  updatepasswordAction =createAsyncThunk('updatepassword',async(obj)=>{

    const  data  = await Api.post("/playerportal/updatepassword", obj);
  
    if(data.data.status==200){
       toast.success("Your password has been reset successfully. You can now log in with your new credentials")

       window.location.href="/";
          
        
       
    }else{
        toast.error(data.data.message)
    }


})


 //send email verification 

 export const sendverificationemailAction =createAsyncThunk('sendverificationemail',async(obj)=>{

    const  data  = await Api.post(`/playerportal/sendverificationemail`,obj);

      if(data.data.status==201){
           return data.data.getclublists
      }

})



export const categoryandsubcategorylistAction =createAsyncThunk('categoryandsubcategorylist',async(obj)=>{

    const  data  = await Api.get(`/webportal/categoryandsubcategorylist`);

      if(data.data.status==200){
      

           return data.data.Listcategorysub
      }

})